import {
    BigBigWork,
    GuideOptions,
    checkRedEnvelopeCookie,
    cookieRemove,
    cookieSet,
    cookieGet,
    waitLogin,
    getCrossCookie, getRedEnvelopeCookie
} from "bbw-common" ;
import Vue from "vue" ;
import Cookies from "js-cookie" ;
export const GuideConfig:GuideOptions[] = [
    {
        name: `大红包`,
        order: 3,
        conditionEvent: `_START_GUIDE`,
        once: true,
        onAction: async ( guide, store ) => {
            try {
                console.log ( `大红包` ) ;
                // 删除P的大红包
                await cookieSet ( `RedEnvelope`, ``, { domain: ` `, expires: -1, path: `/` }, null, true) ;
                const redEnvelopeCookie = await checkRedEnvelopeCookie ().catch ( e => {
                    throw e ;
                } ) ;
                try {
                    await store.dispatch ( `RedEnvelope/FetchRedEnvelope` ) ;
                } catch ( e ) {
                    console.dir ( e ) ;
                }
                if ( redEnvelopeCookie ) {
                    // 本地无cookie 弹框
                    const DialogRedEnvelopeVue = await import ( `bbw-components/src/components/DialogRedEnvelope` ).catch ( e => {
                        throw e ;
                    } ) ;
                    // @ts-ignore
                    // await import ( `../../../../node_modules/bbw-components/lib/assets/DialogRedEnvelope.css` ).catch ( e => {
                    //     throw e ;
                    // } ) ;
                    const DialogRedEnvelope = new Vue ( { ...DialogRedEnvelopeVue.default, propsData: { auth: true } } ) ;
                    DialogRedEnvelope.$store = store ;
                    const el = document.createElement ( `div` ) ;
                    document.body.appendChild ( el ) ;
                    DialogRedEnvelope.$mount ( el ) ;
                    store.dispatch ( `RedEnvelope/toggleDialogRed`, true ) ;
                } else {
                    BigBigWork.emit ( `_GUIDE_NextGuide`, `_SEARCH_GUIDE` ) ;// 下一个向导
                }
            } catch ( e ) {
                BigBigWork.emit ( `_GUIDE_NextGuide`, `_START_GUIDE` ) ;// 下一个向导
                throw e ;
            }
        }
    },
    {
        name: `福利弹窗`,
        order: 4,
        conditionEvent: `_SEARCH_GUIDE`,
        once: true,
        onAction: async (guide,store) =>{
            try {
                await waitLogin ( store ) ;
                console.dir ( `福利弹窗` ) ;
                // 测试弹出
                const test = window.localStorage.getItem ( `test_fuli` ) ;
                // 测试必弹福利弹窗
                if ( test ) {
                    const now = new Date ().getTime () ;
                    store.commit ( `RedEnvelope/updatePDialogFuli`, { closeFuliDialog: now } ) ;
                }
                // const redEnvelopeCookie = await checkRedEnvelopeCookie ();
                // const redEnvelopeCookie = false;
                /*
                 *弹窗触发用户
                 * */
                //当前是不是有红包出现
                const isShowRedEnvelopes = !store.state.RedEnvelope.isShowRedEnvelope;
                // const isShowRedEnvelopes = true;
                // 有没有弹过红包
                const hasRedEnvelopesOnce = await getRedEnvelopeCookie () ;
                // const hasRedEnvelopesOnce = true ;
                // 没有和第二次红包冲突
                // const guideShowRedEnvelopes = !store.state.RedEnvelope.guideShowRedEnvelopes ;
                // 用户没有关注过企业微信  null 是没有关注过
                const notFollowWeixin = store.state.UserInfo.wx.exclusive_service_id === null ;
                // 用户登录天数大于等于8天
                const loginDays = store.state.UserInfo.loginDays >= 8 ;
                // vip 剩余天数 = 0 （是不是vip  0的话就不是）
                const noVip = store.state.UserInfo.vipDays === 0 ;
                // 有没有弹过【福利弹框】
                // const notShowFuli = !( await cookieGet ( `pinliteFuli` )) ;
                const notShowFuli = !(await getCrossCookie ( `pinliteFuli` ));
                // 是否是 pinlite的搜索页
                const isSearch = !!store.state.route.query.kw;
                /*
                显示福利弹窗满足的条件：
                http://doc.bigbigwork.com/docs/fufei88888888/2573
                */
                console.log(` %c 当前有无红包弹窗 ${isShowRedEnvelopes}`, 'font-size:20px; color:blue');
                console.log(` %c 有没有弹过红包 ${hasRedEnvelopesOnce}`, 'font-size:20px; color:blue');
                console.log(` %c 用户没有关注过企业微信 true是没有 ${notFollowWeixin}`, 'font-size:20px; color:blue');
                console.log(` %c 登录天数大于等于8天 ${loginDays}`, 'font-size:20px; color:blue');
                console.log(` %c vip 剩余天数 = 0 ${noVip}`, 'font-size:20px; color:blue');
                console.log(` %c 有没有弹过【福利弹框】 ${notShowFuli}`, 'font-size:20px; color:blue');
                console.log(` %c 是否是 pinlite的搜索页 ${isSearch}`, 'font-size:20px; color:blue');

                const isShowFuli1 = isShowRedEnvelopes && loginDays && noVip && notFollowWeixin && hasRedEnvelopesOnce && notShowFuli && isSearch ;
                // 是否显示弹窗
                const isShowFuli = isShowFuli1 ;

                if (test || isShowFuli ){
                    // 百度统计
                    // @ts-ignore
                    _hmt.push ( [`_trackEvent`, `福利弹框`, `系统弹框`, `pinlite搜索`] ) ;
                    if ( !store.state.RedEnvelope.pdialogFuli.component){
                        const dialogFuli = await import('bbw-components/src/components/DialogFuli_p_new');
                        store.commit('RedEnvelope/updatePDialogFuli',{component: dialogFuli.default});
                    }
                    store.dispatch ( `RedEnvelope/togglePDialogFuli`, { show: true} ) ;
                } else {
                    BigBigWork.emit ( `_GUIDE_NextGuide`, `_START_GUIDE` ) ;// 下一个向导
                }
            }catch (e) {
                BigBigWork.emit ( `_GUIDE_NextGuide`, `_START_GUIDE` ) ;// 下一个向导
            }
        }
    }
] ;

// @ts-ignore
if ( module.hot ) {
    // @ts-ignore
    module.hot.accept () ;
}
