import VueRouter from 'vue-router' ;
import Vue from 'vue' ;
import { PagePinliteUnlogin } from "bbw-common" ;
import path from "path";

if( process.env.VUE_ENV === 'server') {
    // 获取环境变量
    require ( `dotenv` ).config ( { path: path.resolve ( __dirname, `../../../.project.env` ) } ) ;
}

Vue.use ( VueRouter ) ;

const PageIndex = () => import ( /* webpackChunkName:"pagehome" */ `../src/pages/tupian/tupian.vue` ) ;
const PageImage = () => import ( /* webpackChunkName:"pageImage" */ `../src/pages/image/image.vue` ) ;
const Page404 = () => import ( /* webpackChunkName:"page404" */ `../src/pages/404/404.vue` ) ;
const PageUnlogin = () => import ( /* webpackChunkName:"pageUnlogin" */ `../src/pages/unlogin/unlogin.vue` ) ;
const PageAuth = () => import ( /* webpackChunkName:"pageAuth" */ `../src/pages/auth/auth.vue` ) ;
const PageLeave = () => import ( /* webpackChunkName:"pageLeave" */ `../src/pages/leave/leave.vue` ) ;
const PageSem = () => import ( /* webpackChunkName:"pageSem" */ `../src/pages/sem/sem.vue` ) ;
const PageSem_a = () => import ( /* webpackChunkName:"pageSem" */ `../src/pages/sem/sem_a.vue` ) ;
const PageSem_b = () => import ( /* webpackChunkName:"pageSem" */ `../src/pages/sem/sem_b.vue` ) ;
const PageSem_c = () => import ( /* webpackChunkName:"pageSem" */ `../src/pages/sem/sem_c.vue` ) ;
const PageSem_h = () => import ( /* webpackChunkName:"pageSem" */ `../src/pages/sem/sem_h.vue` ) ;
const PageSemTg = () => import ( /* webpackChunkName:"pageSem" */ `../src/pages/sem/semExtension.vue` ) ;
const PageSemTg_a = () => import ( /* webpackChunkName:"pageSem" */ `../src/pages/sem/semExtension_a.vue` ) ;
const PageSemTg_b = () => import ( /* webpackChunkName:"pageSem" */ `../src/pages/sem/semExtension_b.vue` ) ;
const PageSemTg_c = () => import ( /* webpackChunkName:"pageSem" */ `../src/pages/sem/semExtension_c.vue` ) ;
const PageSemTg_h = () => import ( /* webpackChunkName:"pageSem" */ `../src/pages/sem/semExtension_h.vue` ) ;
export default function CreateRouter () {
    const Router = new VueRouter ( {
        mode: `history`,
        fallback: false,
        scrollBehavior: ( to, from, savedPosition ) => {
            if ( savedPosition && to.meta.keepAlive ) {
                return savedPosition ;
            }
            return { x: 0, y: 0 } ;
        },
        routes: [
            {
                path: `/`,
                redirect: { name: 'home' }
            },
            {
                path: `/index`,
                component: PageUnlogin
            },
            {
                path: `/tupian/image/:imgid`,
                component: PageImage
            },
            {
                path: `/tupian`,
                component: PageIndex,
                name: "home"
            },
            {
                path: `/unlogin`,
                component: PageIndex
            },
            // {
            //     path: `/auth`,
            //     component: PageAuth
            // },
            {
                path: `/leave`,
                component: PageLeave
            },
            {
                path: `/sem`,
                component: PageSem
            },
            {
                path: `/sem_a`,
                component: PageSem_a
            },
            {
                path: `/sem_h`,
                component: PageSem_h
            },
            {
                path: `/sem_b`,
                component: PageSem_b
            },
            {
                path: `/sem_c`,
                component: PageSem_c
            },
            {
                path: `/semtg`,
                component: PageSemTg
            },
            {
                path: `/semtg_a`,
                component: PageSemTg_a
            },
            {
                path: `/semtg_b`,
                component: PageSemTg_b
            },
            {
                path: `/semtg_c`,
                component: PageSemTg_c
            },
            {
                path: `/semtg_h`,
                component: PageSemTg_h
            },
            {
                path: `/404`,
                component: Page404
            },
            {
                path: `*`,
                component: Page404
            }
        ]
    } ) ;
    return Router ;
}
// @ts-ignore
if ( module.hot ) {
    // @ts-ignore
    module.hot.accept () ;
}
