<template>
    <div id="app">
        <BannerBrowser v-if="$store.state.isIE"></BannerBrowser>
        <keep-cache :key="$route.fullPath">
            <router-view></router-view>
        </keep-cache>
      <component :is="pdialogFuli.component" :show="pdialogFuli.show"></component>
    </div>
</template>
<script lang=ts>
import {AnalysisPinliteBaiduId, AnalysisBigNetBaiduId, AnalysisPinliteCCBaiduId,AnalysisBigBigWorkBaiduId, AnalysisInit} from '@scripts/Analysis'
import {BannerBrowser} from "bbw-components";
import {checkLogin} from "@scripts/util";
import {getDomain, SearchProxy, waitLogin, keepCache, BigBigWork} from "bbw-common";
export default {
  name: 'entry',
  components: { BannerBrowser, keepCache },
  methods: {
    initAnalysis: function (analyId) {
      (function () {
        var hm = document.createElement("script");
        hm.src = `https://hm.baidu.com/hm.js?${analyId}`;
        var s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(hm, s);
      })();
    },
    handleDzStats ( event ) {
        BigBigWork.emit( `Analysis`, {
              type: `dzStats`,
              data: {
                  ...event,
                  store: { state: this.$store.state }
              }
        })
    },
  },
  async beforeMount() {
    BigBigWork.addListener( `DZ_STATS`, this.handleDzStats )
    window.setTimeout(()=>{
      if (location.origin.includes('bigbigwork.net')){
        this.initAnalysis(AnalysisBigNetBaiduId)
        return
      }
      if(getDomain()=== '.pinlite.cc'){
        this.initAnalysis(AnalysisPinliteCCBaiduId);
      }else if(getDomain()=== '.bigbigwork.com'){
        this.initAnalysis(AnalysisBigBigWorkBaiduId);
      }else{
        this.initAnalysis(AnalysisPinliteBaiduId);
      }
      // 初始化统计系统
      AnalysisInit () ;
    },2000)
    this.$store.commit("checkIE");
    await waitLogin(this.$store)
    this.$store.dispatch("FavBoard/loadResentBoard");
  },
  computed: {
    pdialogFuli(){
      return this.$store.state.RedEnvelope?.pdialogFuli || {}
    }
  }
}
</script>
