import Vue from 'vue' ;
import Vuex from 'vuex' ;
import {
    StorePinliteUser,
    StoreRedEnvelope,
    StoreDesigner,
    StoreMessage,
    StoreUserInfo,
    StoreFavBoard,
    StoreData,
    StoreSystem,
    StoreAiEntry
} from "bbw-common" ;
import Bowser from "bowser" ;

Vue.use ( Vuex ) ;

export type PinliteState = {
    title: string,
    server: boolean,
    clientWidth: number,
    debug: unknown,
    token: string,
    cache: {},
    bannerUnlogin:{
        ready: boolean,
        content:string,
        backgroundColorStart:string,
        backgroundColorEnd:string,
        backgroundImage: string,
        openUrl:string,
        openUrl_h:string,
        domain:string
    },
    analysis:{
        searchword: string
    },
    isIE:boolean,
    dropDownIndex: null,
    dropDownIndexVal: string,
    isPickingColor: boolean
}

export default function CreateStore () {
    return new Vuex.Store<PinliteState> ( {
        state: {
            title: `Pinlite-Pinterest中文极速版官网网站 `,
            /** 是否处于服务端 */
            server: true,
            /** 屏幕宽度 */
            clientWidth: 1200,
            /** debug信息存放处 */
            debug: {},
            /** 用户请求带来的token */
            token: ``,
            /** 用于服务器和客户端共享的缓存 */
            cache: {},
            /** 未登录页banner条信息 */
            bannerUnlogin: {
                ready: false,
                content: ``,
                backgroundColorStart: ``,
                backgroundColorEnd: ``,
                backgroundImage: ``,
                openUrl: ``,
                openUrl_h: ``
            },
            /** 统计用赋值 */
            analysis: {
                searchword: ``
            },
            isIE: false,
            /** 推荐词条目 */
            dropDownIndex: null,
            /** 推荐词 */
            dropDownIndexVal: ``,
            /** 是否要选取颜色 */
            isPickingColor: false
        },
        mutations: {
            setTitle: function ( state, val ) {
                state.title = val ;
            },
            setDebug: function ( state, data ) {
                const { key, value } = data ;
                state.debug[key] = value ;
            },
            setIsClient: function ( state ) {
                state.server = false ;
            },
            setClientWidth: function ( state, val ) {
                state.clientWidth = val ;
            },
            setToken: function ( state, val ) {
                state.token = val ;
            },
            setCache: function ( state, data ) {
                const { key, value } = data ;
                state.cache[key] = value ;
            },
            /** 设置未登录banner */
            setBannerUnlogin: function ( state, data ) {
                state.bannerUnlogin.content = data.content ?? `` ;
                state.bannerUnlogin.backgroundColorStart = data.backgroundColorStart ?? `` ;
                state.bannerUnlogin.backgroundColorEnd = data.backgroundColorEnd ?? `` ;
                state.bannerUnlogin.backgroundImage = data.backgroundImage ?? `` ;
                state.bannerUnlogin.openUrl = data.openUrl ?? `https://p.bigbigwork.com/pinlite/register.html?redirect=https%3A%2F%2Fp.bigbigwork.com%2Fpinlite%2FloginAuth.html%3Fredirect%3D%2F%2F${encodeURIComponent('https://' + data.domain + '/tupian')}` ;
                state.bannerUnlogin.openUrl_h = data.openUrl_h ?? `https://p.bigbigwork.com/loginnew.htm` ;
                state.bannerUnlogin.ready = true ;
                state.bannerUnlogin.domain = data.domain;
            },
            /** 设置统计信息 */
            setAnalysis: function ( state, data ) {
                const { searchword = `` } = data ;
                state.analysis.searchword = searchword ;
            },
            checkIE: function ( state, data ) {
                const browser = Bowser.getParser ( window.navigator.userAgent ) ;
                state.isIE = browser.getBrowserName ( true ) === `internet explorer` ;
            },
            updateDropDownIndex: function ( state, data ) {
                state.dropDownIndex = data ;
            },
            updateDropDownValue: function ( state, data ) {
                state.dropDownIndexVal = data ;
            },

            /** 设置是否在选取颜色 */
            setIsPickingColor: function ( state, data ) {
                state.isPickingColor = data ;
            }
        },
        actions: {
            FETCH_INDEX_TITLE: ( context ) => {
                return Promise.resolve ( true ).then ( () => {
                    context.commit ( `setTitle`, `PinLite` ) ;
                    return true ;
                } ) ;
            }
        },
        getters: {
            masonryWidth: ( state ) => {
                const rest = ( state.clientWidth + 16 ) % ( 236 + 16 ) ;
                return ( state.clientWidth ) - rest ;
            },
            token: ( state ) => {
                return state.token ;
            }
        },
        modules: {
            PinUser: StorePinliteUser.PinUser,
            RedEnvelope: StoreRedEnvelope.RedEnvelope,
            UserInfo: StoreUserInfo.UserInfo,
            Message: StoreMessage.Message,
            Designer: StoreDesigner.Designer,
            FavBoard: StoreFavBoard.FavBoard,
            Data: StoreData.Data,
            System: StoreSystem.System,
            AiEntry: StoreAiEntry.AiEntry
        }
    } ) ;
}
