var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _vm.$store.state.isIE ? _c("BannerBrowser") : _vm._e(),
      _vm._v(" "),
      _c("keep-cache", { key: _vm.$route.fullPath }, [_c("router-view")], 1),
      _vm._v(" "),
      _c(_vm.pdialogFuli.component, {
        tag: "component",
        attrs: { show: _vm.pdialogFuli.show },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }